import React, { useState, useEffect } from "react";
import OTPForm from "./OTPForm";
import { Button } from "@material-ui/core";
import { loginUser, corporateLogin } from "shared/services/customer";

export function LoginForm() {
  //let dummyInput: any = React.createRef();
  let phoneNumberInput: any = React.createRef();

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [phoneNumber, setPhonenumber] = useState(0);
  const [isSending, setSending] = useState(false);
  const [customerAuthId, setCustomerAuthId] = useState("");

  useEffect(() => {
    setOtpSent(false);
    phoneNumberInput.current.focus();
    window.scroll(0, 0);
  }, [0]);

  function handleSubmitPhonenumber(event: any) {
    setSending(true);
    setSending(true);
    if (phoneNumber.toString().length == 10) {
      setPhonenumber(phoneNumber);
      setLoginError(false);
      corporateLogin(phoneNumber.toString())
        .then((res) => {
          setCustomerAuthId(res.data.customerOTPAuthorizationId);
          setOtpSent(true);
          setSending(false);
        })
        .catch((error) => {
          setSending(false);
          setLoginError(true);
          if (error.request.response) {
            let errorRequest = JSON.parse(error.request.response);
            setLoginErrorMessage(errorRequest.message);
          } else {
            setLoginErrorMessage("Sorry, something went wrong!");
          }
        });
    } else {
      setLoginError(true);
      setSending(false);
      setLoginErrorMessage("Phone number is incorrect. Please try again.");
    }
    event.preventDefault();
  }
  function onkeypress(data: any) {
    if (data.currentTarget.value.length > 10) {
      phoneNumberInput.current.value = data.currentTarget.value.slice(
        0,
        data.currentTarget.maxLength
      );
    }
    setPhonenumber(
      parseInt(data.currentTarget.value.slice(0, data.currentTarget.maxLength))
    );
  }
  return (
    <div className="layout--login--form">
      <h4 className="title">Login</h4>
      <div hidden={isOtpSent}>
        <h6 className="description mt-3">Enter your phone number (+91)</h6>
        <form className="form-phone mt-4" onSubmit={handleSubmitPhonenumber}>
          <div id="divOuter">
            <div id="divInner">
              <input
                ref={phoneNumberInput}
                autoFocus={true}
                id="partitioned"
                type="number"
                maxLength={10}
                onKeyDown={(evt) => {
                  (evt.key === "e" || evt.key == ".") && evt.preventDefault();
                }}
                onChange={onkeypress}
                inputMode="numeric"
              />
              {/* <input
                type="text"
                className="position-absolute dummy-input"
                ref={dummyInput}
              /> */}
            </div>
          </div>
          <h6 className="error-message" hidden={!loginError}>
            {loginErrorMessage}
          </h6>
          <Button
            className="btn-primary-b my-4 submit-button"
            disabled={phoneNumber.toString().length == 10 ? false : true}
            type="submit"
            size="large"
          >
            Send OTP
            <svg
              className="loader-svg"
              version="1.1"
              id="loader-1"
              x="0px"
              y="0px"
              width="25px"
              height="25px"
              viewBox="0 0 50 50"
              enableBackground="new 0 0 50 50"
              display={isSending ? "inherit" : "none"}
            >
              <path
                fill="#fff"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </Button>
        </form>
      </div>
      <div hidden={!isOtpSent}>
        <OTPForm isOtpSent={isOtpSent} phoneNumber={phoneNumber.toString()} customerAuthId={customerAuthId} />
      </div>
    </div>
  );
}
