import React from "react"

export function Loader(){
    return(
        <div className="loader-container justify-content-center align-items-center d-flex">
        <svg
          version="1.1"
          id="L4"
          height="20px"
          width="54px"
          enableBackground="new 0 0 0 0"
        >
          <circle fill="#484987" stroke="none" cx="6" cy="10" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#484987" stroke="none" cx="26" cy="10" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#484987" stroke="none" cx="46" cy="10" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
    )
}