import React, { useEffect } from "react";
import loginImage from "../assets/images/img-login.svg";
import { LoginForm } from "../shared/components/LoginForm";

const Login = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="layout--login">
      <img
        className="logo m-3"
        src={process.env.REACT_APP_LOGO}
        alt={process.env.REACT_APP_SITE_NAME}
      />
      <div className="layout-content mx-auto">
        <div className="left-section  d-flex justify-content-center">
          <img className="ad-image" src={loginImage} alt="" />
        </div>
        <div className="login-container">
          <LoginForm />
        </div>
      </div>
      <span className="copyright-text">
        Copyright © {new Date().getFullYear()} {process.env.REACT_APP_SITE_NAME}
      </span>
    </div>
  );
};

export default Login;
