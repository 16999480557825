import React, { useEffect, useState, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { getOnGoingvehicles } from "shared/services/vehicle";
import { TableRow, Button, Link, TablePagination } from "@material-ui/core";
import { numberWithCommas } from "shared/utils/formatStrings";
import { CarDetails } from "shared/components/CarDetailsModal";
import { emptyOnGoingSale, OnGoingSaleModel } from "shared/models/OnGoing";
import { PriceBreakdown } from "shared/components/PriceBreakdown";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";
import ReducePriceModal from "shared/components/ReducePriceModal";
import { Loader } from "shared/components/Loader";
import SellAtOfferPrice from "shared/components/SellAtOfferPriceModal";
import GetAppIcon from "@material-ui/icons/GetApp";
import createXLData from "shared/utils/createXLJson";

type Props = {
  addedNewCar: boolean;
  reloadList: any;
  onGoingData: any;
};

function OnGoingSale(props: Props) {
  const [vehicleData, setVehicleData] = useState<OnGoingSaleModel[]>([]);
  const [sellingFormat, setSellingFormat] = useState(0);
  const [status, setStatus] = useState(0);
  const [sortBy, setSortBy] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(emptyOnGoingSale);
  const [priceBreakdownModal, setPriceBreakdownModal] = useState(false);
  const [reducePriceModal, setReducePriceModal] = useState(false);
  const [sellAtOfferPriceModal, setSellAtOfferPriceModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<any>(0);

  useEffect(() => {
    if (props.addedNewCar == true) {
      setLoading(true);
      fetchOnGoingCars();
      props.reloadList(false);
    }
    console.log("ADD NEW CAR-------------------------");
  }, [props.addedNewCar]);

  useEffect(() => {
    console.log("PAGE & ROWS-------------------------");
    fetchOnGoingCars();
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("SORT CALLED-------------------------");
    if (vehicleData.length > 0) {
      let newVehicleData = vehicleData;
      switch (sortBy) {
        case 0: {
          newVehicleData = newVehicleData.sort((a: any, b: any) => {
            return (
              new Date(b.created).valueOf() - new Date(a.created).valueOf()
            );
          });
          break;
        }
        case 1: {
          newVehicleData = newVehicleData.sort((a: any, b: any) => {
            let price1 = a.price ? a.price.driveHomePrice : 0;
            let price2 = b.price ? b.price.driveHomePrice : 0;
            return price2 - price1;
          });
          break;
        }
        case 2: {
          newVehicleData = newVehicleData.sort((a: any, b: any) => {
            let price1 = a.price ? a.price.driveHomePrice : 0;
            let price2 = b.price ? b.price.driveHomePrice : 0;
            return price1 - price2;
          });
          break;
        }
      }
      setVehicleData(newVehicleData);
    }
  }, [sortBy]);

  function fetchOnGoingCars() {
    getOnGoingvehicles(page + 1, rowsPerPage)
      .then((response) => {
        let sortedData: any = response.data.items.sort((a: any, b: any) => {
          return new Date(a.created).valueOf() - new Date(b.created).valueOf();
        });
        setVehicleData(sortedData);
        setCount(response.data.itemsSummary);
        setLoading(false);
        props.onGoingData(response.data.itemsSummary);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handleCloseDetails() {
    setShowDetails(false);
  }

  function handleClosePriceBreakdown() {
    setPriceBreakdownModal(false);
  }

  function handleCloseReducePrice() {
    setReducePriceModal(false);
  }

  function handleCloseSellAtOfferPrice() {
    setSellAtOfferPriceModal(false);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function downloadToExcel() {
    getOnGoingvehicles(0, 0, true).then((res) => {
      createXLData(res.data.items, "OnGoing Sale List");
    });
  }

  if (!loading) {
    if (vehicleData.length > 0) {
      return (
        <div className="layout-ongoing-sale">
          <CarDetails
            openModal={showDetails}
            details={selectedRowData}
            handleClose={handleCloseDetails}
          />
          <PriceBreakdown
            openModal={priceBreakdownModal}
            details={selectedRowData}
            handleClose={handleClosePriceBreakdown}
          />
          <ReducePriceModal
            openModal={reducePriceModal}
            details={selectedRowData}
            handleClose={handleCloseReducePrice}
          />
          <SellAtOfferPrice
            openModal={sellAtOfferPriceModal}
            details={selectedRowData}
            handleClose={handleCloseSellAtOfferPrice}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<GetAppIcon />}
            className="download-btn mt-3"
            onClick={() => {
              downloadToExcel();
            }}
          >
            Download
          </Button>
          {/* <div className="d-flex align-items-center mr-5"> */}
          {/* <span className="mr-3" style={{ fontWeight: "bold" }}>
              Selling Format
            </span>
            <FormControl variant="outlined" size="small" style={{ width: 155 }}>
              <Select
                value={sellingFormat}
                onChange={(e: any) => {
                  setSellingFormat(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Show All</MenuItem>
                <MenuItem value={1}>Park & Sell</MenuItem>
                <MenuItem value={2}>Auction</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {/* <div className="d-flex align-items-center mr-5">
            <span className="mr-3" style={{ fontWeight: "bold" }}>
              Status
            </span>
            <FormControl variant="outlined" size="small" style={{ width: 155 }}>
              <Select
                value={status}
                onChange={(e: any) => {
                  setStatus(parseInt(e.target.value));
                }}
              >
                <MenuItem value={0}>Show All</MenuItem>
                <MenuItem value={1}>Under Refurbishment</MenuItem>
                <MenuItem value={2}>Auction</MenuItem>
                <MenuItem value={3}>On Sale</MenuItem>
              </Select>
            </FormControl>
          </div>*/}
          {/* <div className="d-flex align-items-center ml-auto">
                <span className="mr-3" style={{ fontWeight: "bold" }}>
                  Sort by
                </span>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "auto" }}
                >
                  <Select
                    value={sortBy}
                    onChange={(e: any) => {
                      setSortBy(parseInt(e.target.value));
                    }}
                  >
                    <MenuItem value={0}>Latest</MenuItem>
                    <MenuItem value={1}>Price: High to Low</MenuItem>
                    <MenuItem value={2}>Price: Low to High</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
          {/* </div> */}
          <Paper className="mt-3">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Make & Model
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Selling Format
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Status Of Car
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Likes</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Views</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Test Drive
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Added On
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Sale Value
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vehicleData.map((row, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let status = row.status.split("_").join(" ");
                    let format =
                      row.sellingFormat == "auction"
                        ? row.sellingFormat
                        : "Park & Sell";
                    let price: any = "-";
                    let options: any = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    let date = new Date(row.created).toLocaleDateString(
                      "en-US",
                      options
                    );
                    if (
                      row.sellingFormat.toLowerCase() == "auction" &&
                      row.auction
                    ) {
                      price = row.auction.driveHomePrice
                        ? numberWithCommas(row.auction.driveHomePrice)
                        : "-";
                    } else if (row.sellingFormat !== "auction" && row.price) {
                      price = row.price.driveHomePrice
                        ? numberWithCommas(row.price.driveHomePrice)
                        : "-";
                    }
                    return (
                      <TableRow hover title="click to view details" key={index}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {(page + 1 - 1) * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {`${row.make.toLowerCase()} ${row.model.toLowerCase()}`}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {format}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {status}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count.favourite ? row.count.favourite : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count.view ? row.count.view : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {row.count.testDrive ? row.count.testDrive : 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          onClick={(event) => {
                            setShowDetails(true);
                            setSelectedRowData(row);
                          }}
                        >
                          {date}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize font-weight-bold"
                        >
                          {price}
                          <Link
                            component="button"
                            className="ml-2 link-button"
                            hidden={price !== "-" && price != "" ? false : true}
                            onClick={() => {
                              setSelectedRowData(row);
                              setPriceBreakdownModal(true);
                              setShowDetails(false);
                            }}
                          >
                            View Price Breakdown
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-capitalize"
                          title="click to reduce price"
                        >
                          <Button
                            variant="outlined"
                            style={{ borderRadius: 20, fontSize: 12 }}
                            hidden={
                              price != "-" &&
                              price != "" &&
                              row.status.toLowerCase() == "listed_unreserved"
                                ? false
                                : true
                            }
                            onClick={() => {
                              setSelectedRowData(row);
                              setReducePriceModal(true);
                            }}
                          >
                            Reduce
                          </Button>
                          <Button
                            variant="outlined"
                            style={{ borderRadius: 20, fontSize: 12 }}
                            hidden={
                              price != "-" &&
                              price != "" &&
                              row.status.toLowerCase() == "auctioned"
                                ? false
                                : true
                            }
                            onClick={() => {
                              setSelectedRowData(row);
                              setSellAtOfferPriceModal(true);
                            }}
                          >
                            Sell at offer price
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={count.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      );
    } else {
      return (
        <div className="mt-4 text-center">No vehicles on sale currently</div>
      );
    }
  } else {
    return (
      <div>
        <Loader />
      </div>
    );
  }
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const mapStateToProps = (state: any) => {
  return {
    addedNewCar: state.Subscriptions.addedNewCar,
  };
};

export default connect(mapStateToProps, subscriptionAction)(OnGoingSale);
