import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { OnGoingSaleModel } from "shared/models/OnGoing";
import { numberWithCommas } from "shared/utils/formatStrings";

type Props = {
  openModal: boolean;
  details: OnGoingSaleModel;
  handleClose: any;
};

export function PriceBreakdown(props: Props) {
  if (props.details.price) {
    let refurbishmentPrice = 0;
    if (props.details.price.agreedCharges) {
      refurbishmentPrice = props.details.price.agreedCharges
        ? props.details.price.agreedCharges
        : 0;
    }
    let gigaFee: any = "-";
    let auctionPrice: any = "-";
    if (
      props.details.sellingFormat.toLowerCase() == "auction" &&
      props.details.auction
    ) {
      gigaFee = numberWithCommas(
        props.details.auction.listingFee ? props.details.price.listingFee : 0
      );
      auctionPrice = numberWithCommas(
        props.details.auction.auctionPrice
          ? props.details.price.auctionPrice
          : 0
      );
    } else if (
      props.details.sellingFormat != "auction" &&
      props.details.price
    ) {
      gigaFee = numberWithCommas(
        props.details.price.listingFee ? props.details.price.listingFee : 0
      );
    }
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.openModal}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="price--breakdown"
      >
        <DialogTitle>Price Breakdown</DialogTitle>
        <DialogContent>
          <DialogContentText tabIndex={-1}>
            <div className="container">
              <div
                className="mb-2"
                hidden={
                  props.details.sellingFormat.toLowerCase() == "auction"
                    ? true
                    : false
                }
              >
                <h6 className="title mb-0">Giga Drive Home Price</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.driveHomePrice
                      ? props.details.price.driveHomePrice
                      : 0
                  )}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">GC Success Fee</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.gcSuccessFee
                      ? props.details.price.gcSuccessFee
                      : 0
                  )}
                </h5>
              </div>
              <div
                className="mb-2"
                hidden={
                  props.details.sellingFormat.toLowerCase() == "auction"
                    ? true
                    : false
                }
              >
                <h6 className="title mb-0">Net Purchase Price of Vehicle</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.netPurchasePrice
                      ? props.details.price.netPurchasePrice
                      : 0
                  )}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">Listing Fixed Fee</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.listingFee
                      ? props.details.price.listingFee
                      : 0
                  )}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">Third Party Insurance</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.insurance
                      ? props.details.price.insurance
                      : 0
                  )}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">B Extract, Hyp Cancel</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.extraCharges
                      ? props.details.price.extraCharges
                      : 0
                  )}
                </h5>
              </div>
              <div
                className="mb-2"
                hidden={
                  props.details.sellingFormat.toLowerCase() == "auction"
                    ? true
                    : false
                }
              >
                <h6 className="title mb-0">
                  Invenstment of CarRefresh
                </h6>
                <h5 className="font-weight-bold">
                  ₹ {numberWithCommas(refurbishmentPrice)}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">
                  Traffic Fines
                </h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.trafficFines
                      ? props.details.price.trafficFines
                      : 0
                  )}
                </h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">
                  Net Purchase Price Payable to Seller
                </h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.netPayableSellerPrice
                      ? props.details.price.netPayableSellerPrice
                      : 0
                  )}
                </h5>
              </div>

              <div className="mb-2">
                <h6 className="title mb-0">GC Cashback / Promotional</h6>
                <h5 className="font-weight-bold">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.gcCashback
                      ? props.details.price.gcCashback
                      : 0
                  )}
                </h5>
              </div>
              <div
                className="mb-2"
                hidden={
                  props.details.sellingFormat.toLowerCase() == "auction"
                    ? false
                    : true
                }
              >
                <h6 className="title mb-0">Auction break</h6>
                <h5 className="font-weight-bold">₹ {auctionPrice}</h5>
              </div>
              <div className="mb-2">
                <h6 className="title mb-0">Seller Final Value</h6>
                <h3 className="font-weight-bold text-purple">
                  ₹{" "}
                  {numberWithCommas(
                    props.details.price.netPayableSellerPrice
                      ? props.details.price.netPayableSellerPrice
                      : 0
                  )}
                </h3>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <div></div>;
  }
}
